.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: 1;
  background-color: var(--clr-bg);
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 2rem !important;
  color: var(--clr-txt);
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 3em !important;
  padding-left: 45px !important;
}

.main-name {
  color: var(--clr-primary) !important;
}

.Typewriter__wrapper {
  font-size: 2em !important;
  color: var(--clr-primary) !important;
  font-weight: 600 !important;
  padding-left: 25px;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: var(--clr-primary) !important;
}

@media (max-width: 991px) {
  .heading {
    font-size: 2.3em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 2em !important;
    padding-left: 0px !important;
    text-align: left !important;
  }
  .type{
    padding-left: 0px;
  }
  .Typewriter__wrapper {
    font-size: 1.3em !important;
    padding: 0px !important;
  }
  .Typewriter__cursor {
    font-size: 1.5em !important;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 2.2em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }

  .heading-name {
    font-size: 3em !important;
    padding-left: 20px !important;
    text-align: left !important;
  }
  .type{
    text-align: left !important;
  }
  .Typewriter__wrapper {
    text-align: center !important;
    font-size: 1.2em !important;
    font-weight: 400 !important;
    position: absolute;
  }

  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
  background-color: var(--clr-bg-alt);
}

.home-about-description {
  color: var(--clr-txt) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center !important;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: var(--clr-txt) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: var(--clr-bg) !important;
  box-shadow: var(--shadow) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: var(--clr-primary);
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--clr-primary);
}

.home-social-icons:hover {
  color: var(--clr-primary);
  box-shadow: 0 0 5px var(--clr-primary);
  text-shadow: 0 0 2px var(--clr-primary);
}

.social-icons {
  display: inline-block !important;
  padding-right: 10px;
  padding-left: 10px;
  color: var(--clr-primary);
}

.icon-colour {
  color: var(--clr-primary) !important;
}
